<template>
  <div>
    <PageHeader />
    <div class="d-flex justify-content-between align-items-center mb-2">
      <b-button-group>
        <b-button
          v-for="(game, key) in filteredGameTypes"
          :key="key"
          :variant="currentMenu === game.type ? '' : 'light'"
          @click="currentMenu = game.type"
        >
          {{ game.label }}
        </b-button>
      </b-button-group>
      <month-picker @update="(e) => (selectedDate = e)"></month-picker>
    </div>
    <b-overlay :show="isFetchingGameAgentCommission || isLoading">
      <b-card v-if="currentMenu === 'MINI_GAME'">
        <!-- Table -->
        <template v-if="isDisplayAll">
          <b-form-text>
            {{
              `หมายเหตุ: % คอมมิชชั่นจะถูกไปคิดจากค่าน้ำที่ถูกหักเรียบร้อยแล้ว`
            }}
            <br />
            ยกตัวอย่าง แทงรวม = 400<br />ค่าน้ำ (5%) รวม = 20 บาท รางวัลผู้ชนะ =
            400-20 = 380 ค่าคอมฯ (50% จากค่าน้ำ) รวม = 2.5x4 = 10
          </b-form-text>
          <br />
        </template>
        <b-table-simple hover responsive="">
          <b-thead>
            <b-tr>
              <b-th v-if="isDisplayAll">{{ $t('agent.name') }}</b-th>
              <b-th>{{ $t('games.name') }}</b-th>
              <b-th class="text-right">{{ $t('games.transaction_fee') }}</b-th>
              <b-th>{{ $t('agent.commission') }}</b-th>
              <b-th class="text-right">{{ $t('agent.income') }}</b-th>
            </b-tr>
          </b-thead>
          <template v-if="agentCommissions.length > 0">
            <b-tbody
              v-for="(commission, index) in agentCommissions"
              :key="index"
            >
              <b-tr
                v-for="(game, key) in commission.games"
                :key="`${index}/${key}`"
              >
                <b-th
                  v-if="key === 0 && isDisplayAll"
                  :rowspan="`${commission.games.length}`"
                >
                  <b-link :to="`/agents/detail/${commission.agentId}`">
                    {{ commission.agentUsername || $t('agent.name') }}
                  </b-link>
                </b-th>
                <b-th>
                  {{
                    $te(`games.list.${game.gameType}`)
                      ? $t(`games.list.${game.gameType}`)
                      : game.gameType
                  }}
                </b-th>
                <b-td class="text-right">
                  {{ game.drinkWater | currency }}
                </b-td>
                <b-td> {{ game.commissionRate | money }} %</b-td>
                <b-td class="text-right">
                  {{ game.commissionFee | currency }}
                </b-td>
              </b-tr>
              <b-tr>
                <b-th
                  colspan="5"
                  variant="light"
                  class="text-success text-right"
                >
                  {{ $t('agent.net_income') }}
                  <span v-if="commission.totalsCommission">
                    {{ commission.totalsCommission | currency }}
                  </span>
                </b-th>
              </b-tr>
            </b-tbody>
          </template>
          <b-tbody v-else>
            <b-tr>
              <b-th colspan="5" class="text-center">
                {{ $t('messages.nothing_here') }}
              </b-th>
            </b-tr>
          </b-tbody>
        </b-table-simple>
      </b-card>
      <CommissionsProviderGames
        v-else-if="currentMenu === 'CASINO'"
        :commissions="providerAgentCommissions"
      />
    </b-overlay>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex'
import dayjs from 'dayjs'
import GameProviderService from '../../../services/game-provider'

export default {
  components: {
    CommissionsProviderGames: () =>
      import('../../../components/commissions/provider-games'),
  },
  page: {
    title: 'Commissions',
  },
  data() {
    return {
      currentMenu: '',
      selectedDate: '',
      providerAgentCommissions: [],
      isLoading: false,
      gameTypes: [
        { type: 'MINI_GAME', label: `${this.$t('pages.mini_games.title')}` },
        { type: 'CASINO', label: `${this.$t('pages.provider_games.title')}` },
      ],
    }
  },
  computed: {
    ...mapState({
      isFetchingGameAgentCommission: (state) =>
        state.game.isFetchingGameAgentCommission,
    }),
    ...mapGetters(['userInfo', 'agentCommissions', 'isMaster', 'isOwner']),
    agentId() {
      return this.userInfo.agentId
    },
    filteredGameTypes() {
      return this.gameTypes
    },
    isDisplayAll() {
      return !!(this.isOwner || this.isMaster)
    },
  },
  watch: {
    filteredGameTypes(val) {
      if (val.length > 0 && !this.currentMenu) {
        this.currentMenu = val[0].type
      }
    },
    selectedDate(val) {
      if (val) {
        this.fetchData(val)
      }
    },
    agentId(val) {
      if (val) {
        this.fetchData()
      }
    },
    currentMenu(val) {
      if (val) {
        this.fetchData()
      }
    },
  },
  created() {
    if (this.filteredGameTypes.length > 0) {
      this.currentMenu = this.filteredGameTypes[0].type
    }
    this.fetchData()
  },
  methods: {
    ...mapActions(['fetchGameAgentCommission']),
    async fetchData() {
      const date = dayjs(this.selectedDate || new Date()).format('YYYY-MM-DD')
      if (this.agentId) {
        if (this.currentMenu === 'MINI_GAME') {
          this.fetchGameAgentCommission({
            agentId: this.isDisplayAll ? null : this.agentId,
            date,
          })
        }
        if (this.currentMenu === 'CASINO') {
          this.isLoading = true
          const response = await GameProviderService.fetchAgentCommission({
            // this.userInfo.username
            agentName: this.isDisplayAll ? null : this.userInfo.username,
            startDate: dayjs(this.selectedDate || new Date())
              .startOf('month')
              .format('YYYY-MM-DD'),
            endDate: dayjs(this.selectedDate || new Date())
              .endOf('month')
              .format('YYYY-MM-DD'),
          })
          this.providerAgentCommissions = this.isDisplayAll
            ? response.agentLists
            : response.agentLists.length === 0
            ? []
            : [response.agentLists[0]]
          this.isLoading = false
        }
      }
    },
  },
}
</script>
